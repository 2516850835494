body,
html {
  font-family: sans-serif;
  width: 100%;
  height: 100%;
  background: black;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

#app {
  background: url("sparkler.gif") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

#qr {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: url("qr.png") no-repeat center 40% fixed;
  background-color: black;
  opacity: 0;
  transition: 0.2s all ease-in;
  z-index: 2;
  text-align: center;
}

#qr a, #qr a:visited {
	background: url('hellofuture.svg') no-repeat center top;
	background-size: 9vh;
	padding-top: 10vh;
	position: absolute;
	left: 0;
	right: 0;
	top: 75vh;
	color: #fff;
	text-decoration: none;
	font-size: 13px;
	opacity: .33;
}

#qr a:hover {
	opacity: .66;
}

#qr.show {
  opacity: 1;
}

#share-button {
  position: fixed;
  left: 20px;
  bottom: 20px;
  z-index: 2;
  color: white;
  width: 70px;
  height: 32px;
  border: 1px solid white;
  background: transparent;
}
